import Component from '~/components/component';
import debounce from 'lodash.debounce';

export default class StickySites extends Component {
	constructor(...args) {
		super(...args);
		this.open = false;
		this.prevScroll = 0;

		this.retrieveDom();
		this.bind();
	}

	retrieveDom() {
		this.dom.button = this.dom.component.querySelector('.action');
	}

	toggleOpen = (bool) => {
		if (!this.open && bool) this.dom.component.classList.add('menu-sites-open');
		if (this.open && !bool)
			this.dom.component.classList.remove('menu-sites-open');
		this.open = !this.open;
	};

	handleScroll = () => {
		const scrollY = window.scrollY;
		const halfHeight = window.innerHeight / 2;
		const diff = this.prevScroll - window.scrollY;
		const delta = Math.abs(diff);

		if (delta > 40) {
			if (scrollY > halfHeight) {
				if (diff < 0) {
					this.dom.component.classList.remove('menu-sites-open');
				}
			}

			this.prevScroll = scrollY;
		}
	};

	bind() {
		this._debounceScroll = debounce(this.handleScroll, 5);
		document.addEventListener('scroll', this._debounceScroll);

		this.dom.button.addEventListener('mouseenter', () => this.toggleOpen(true));
		this.dom.component.addEventListener('mouseleave', () =>
			this.toggleOpen(false),
		);
	}

	unbind() {}
}
