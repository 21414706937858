import Swiper, { Pagination, Mousewheel, Autoplay } from 'swiper';
import Component from '~/components/component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class SectionTeamTwo extends Component {
	_init = false;

	constructor(...args) {
		super(...args);
		this.retrieveDOM();
		this.initSwiper();
	}

	retrieveDOM() {
		this.shape = this.dom.component.querySelector('.shape');
		this.dom.sliderWrapper =
			this.dom.component.querySelector('.swiper-container');
		this.dom.pagination =
			this.dom.component.querySelector('.swiper-pagination');
		this.bigCard = this.dom.component.querySelector('#bigCard');
		this.header = document.querySelector('.ct--MenuDesktop');

		setTimeout(() => {
			this.activeChild();
			this.setBigCardData();
		}, 800);
	}

	activeChild() {
		this.prevSlide = this.activeSlide;
		const wrapper = this.dom.sliderWrapper.querySelector('.swiper-wrapper');
		this.activeSlide = wrapper.querySelector(
			'.swiper-slide-active',
		).children[0];
	}

	setBigCardPosition = () => {
		if (!this.activeSlide) return;
		const { x, y } = this.activeSlide.getBoundingClientRect();
		this.innerWrapper.style.top = `${y}px`;
		//this.innerWrapper.style.left = `${x}px`;
	};

	setBigCardData() {
		// SIZE
		const { y, width, height } = this.activeSlide.getBoundingClientRect();

		// GET ACTIVE SLIDE DATA
		var video = false;
		var background = this.activeSlide.querySelector('img');
		if (!background) {
			video = true;
			background = this.activeSlide.querySelector('video');
		}
		if (background) background = background.getAttribute('src');

		const surtitre = this.activeSlide.querySelector('.card-tag').innerHTML;
		const titre = this.activeSlide.querySelector('.card-title').innerHTML;
		const linkWrapper = this.activeSlide.querySelector('a');
		const legSound = this.activeSlide.querySelector('.legende-sound');
		const legSoundHtml = legSound.getAttribute('data-legende');

		var link = { title: null, url: null };
		if (linkWrapper) {
			link = {
				title: this.activeSlide.querySelector('a').getAttribute('title'),
				url: this.activeSlide.querySelector('a').getAttribute('href'),
			};
		}

		// GET AND SET BIG CARD DATA
		this.innerWrapper = this.bigCard.children[0];
		this.innerWrapper.style.width = `${width}px`;
		this.innerWrapper.style.height = `${height}px`;
		this.innerWrapper.style.top = `${y}px`;

		// BACKGROUND
		if (video) {
			const backgroundWrapper =
				this.innerWrapper.children[0].querySelector('.background');
			backgroundWrapper.children[1].setAttribute('src', background);
			this.video = backgroundWrapper.children[1];
		} else {
			const backgroundWrapper =
				this.innerWrapper.children[0].querySelector('.background');
			backgroundWrapper.children[0].setAttribute('src', background);
			this.video = null;
			backgroundWrapper.children[1].setAttribute('src', 'null');
		}

		// SELECTOR
		const surtitreBig = this.innerWrapper.querySelector('.card-tag');
		const titreBig = this.innerWrapper.querySelector('.card-title');
		const linkBig = this.innerWrapper.querySelector('a');
		const legende = this.bigCard.querySelector('.legende');
		const sound = this.bigCard.querySelector('.sound');

		// TEXTE
		surtitreBig.innerHTML = surtitre;
		titreBig.innerHTML = titre;
		if (legSoundHtml.length > 1) {
			legende.classList.remove('hidden');
			legende.innerHTML = legSoundHtml;
		} else legende.classList.add('hidden');

		// LINK
		if (linkWrapper) {
			linkBig.style.display = 'flex';
			linkBig.setAttribute('href', link.url);
			linkBig.setAttribute('title', link.title);
			linkBig.children[0].children[0].innerHTML = link.title;
		} else if (linkBig) linkBig.style.display = 'none';

		// AUDIO
		const soundUrl = legSound.getAttribute('data-sound');
		this.audio = soundUrl ? new Audio(soundUrl) : null;
		sound.style.opacity = soundUrl ? 1 : 0;

		// LISTENER
		if (!this._init) {
			const middle = this.innerWrapper.querySelector('.middle');
			middle.addEventListener('pointerenter', this.onSlideEnter);
			middle.addEventListener('pointerleave', this.onSlideLeave);
			this._init = true;
		}
	}

	onSlideEnter = () => {
		const { x, width, height } = this.activeSlide.getBoundingClientRect();
		const diff = Math.abs(this.innerWrapper.getBoundingClientRect().x - x);

		if (diff > 25) return;

		const scaleRatio =
			1 / Math.max(width / window.innerWidth, height / window.innerHeight);

		const backgroundWrapper = this.innerWrapper.querySelector('.background');

		const backgroundDark = this.bigCard.querySelector('.bigCard-background');

		if (this.audio) this.audio.play();
		if (this.video) this.video.play();

		this.swiper.autoplay.stop();

		this.activeSlide.scrollIntoView({
			behavior: 'auto',
			block: 'center',
			inline: 'center',
		});

		this.header.classList.add('menu-hidden');
		this.bigCard.style.opacity = 1;

		if (this.tween) this.tween.kill();
		backgroundDark.classList.add('visible-bg');
		this.tween = gsap.to(backgroundWrapper, {
			scale: scaleRatio,
			duration: 0.4,
			onComplete: () => {
				this.header.classList.add('menu-hidden');
				gsap.to(this.bigCard.querySelector('.card-bottom'), {
					opacity: 1,
					duration: 0.2,
				});
			},
		});
	};

	onSlideLeave = () => {
		const backgroundWrapper = this.innerWrapper.querySelector('.background');
		const backgroundDark = this.bigCard.querySelector('.bigCard-background');
		if (this.tween) this.tween.kill();

		gsap.to(this.bigCard.querySelector('.card-bottom'), {
			opacity: 0,
			duration: 0.1,
		});

		backgroundDark.classList.remove('visible-bg');
		this.tween = gsap.to(backgroundWrapper, {
			scale: 1,
			duration: 0.4,
			onComplete: () => {
				this.swiper.autoplay.start();
				if (this.audio) this.audio.pause();
				if (this.video) this.video.pause();
				this.bigCard.style.opacity = 0;
			},
		});
	};

	initSwiper() {
		this.swiper = new Swiper(this.dom.sliderWrapper, {
			modules: [Pagination, Mousewheel, Autoplay],
			direction: 'horizontal',
			loop: true,
			centeredSlides: true,
			allowTouchMove: true,
			pagination: {
				el: this.dom.pagination,
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				0: {
					slidesPerView: 'auto',
					spaceBetween: 0,
					autoplay: false,
				},
				1000: {
					slidesPerView: 'auto',
					spaceBetween: 0,
					speed: 1400,
					autoplay: {
						delay: 2000,
					},
				},
			},
		});
	}

	bind() {
		window.addEventListener('scroll', this.setBigCardPosition);

		this.swiper.on('slideChange', () => {
			setTimeout(() => {
				this.activeChild();
				this.setBigCardData();
			}, 20);
		});

		gsap.to(this.shape, {
			yPercent: -50,
			ease: 'none',
			scrollTrigger: {
				trigger: this.dom.component,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 1,
			},
		});
	}
}
