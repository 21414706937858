import Component from '~/components/component';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class HeroTalent extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.images = this.dom.component.querySelectorAll('.imageWrapper');
		this.yValues = [30, 40, 55, 55, 55, 100];
	}

	bind() {
		this.images.forEach((img, i) => {
			gsap.from(img, {
				yPercent: this.yValues[i],
				ease: 'none',
				scrollTrigger: {
					trigger: this.dom.component,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 1,
				},
			});
		});
	}
}
