export const map = {
	components: {
        sectionTeamTwo: require('components/SectionTeamTwo/SectionTeamTwo'),
        cursor: require('components/Cursor/Cursor'),
        navigationAnchors: require('components/NavigationAnchors/NavigationAnchors'),
        blockModulable: require('components/blockModulable/blockModulable'),
        blockIntro: require('components/BlockIntro/BlockIntro'),
        footer: require('components/Footer/Footer'),
        sliderOffer: require('components/SliderOffer/SliderOffer'),
        heroTalent: require('components/HeroTalent/HeroTalent'),
        sectionFull: require('components/SectionFull/SectionFull'),
		dynamicAnchors: require('components/DynamicAnchors/DynamicAnchors'),
		heroGroupe: require('components/HeroGroupe/HeroGroupe'),
		articlesRelated: require('components/ArticlesRelated/ArticlesRelated'),
		share: require('components/Share/Share'),
		heroArticle: require('components/HeroArticle/HeroArticle'),
		stickySites: require('components/StickySites/StickySites'),
		menuMobile: require('components/MenuMobile/MenuMobile'),
		menuDesktop: require('components/MenuDesktop/MenuDesktop'),
		dropdownLang: require('components/DropdownLang/DropdownLang'),
		sectionTeam: require('components/SectionTeam/SectionTeam'),
		sliderJob: require('components/SliderJob/SliderJob'),
		sliderGeneric: require('components/SliderGeneric/SliderGeneric'),
		keyFigures: require('components/KeyFigures/KeyFigures'),
		edito: require('components/Edito/Edito'),
		sliderFull: require('components/SliderFull/SliderFull'),
		header: require('components/Header/Header'),
	},
	pages: {
		simple: {
			index: require('pages/simple/simple-index'),
		},
		contact: {
			index: require('pages/contact/contact-index'),
		},
		newsroom: {
			index: require('pages/newsroom/newsroom-index'),
		},
		actualite: {
			index: require('pages/actualite/actualite-index'),
		},
		documentation: {
			index: require('pages/documentation/documentation-index'),
		},
		espacepresse: {
			index: require('pages/espace-presse/espace-presse-index'),
		},
		newsroom: {
			index: require('pages/newsroom/newsroom-index'),
		},
		talent: {
			index: require('pages/talent/talent-index'),
		},
		job: {
			index: require('pages/job/job-index'),
		},
		groupe: {
			index: require('pages/groupe/groupe-index'),
		},
		homepage: {
			index: require('pages/homepage/homepage-index'),
		},
		homepage_en: {
			index: require('pages/homepage_en/homepage_en-index'),
		},
		article: {
			index: require('pages/article/article-index'),
		},
	},
};
