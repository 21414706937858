import Component from '~/components/component';
import debounce from 'lodash.debounce';

export default class MenuDesktop extends Component {
	constructor(...args) {
		super(...args);

		this.prevScroll = 0;
		this.hidden = false;
		this.active = false;

		this.bind();
	}

	handleScroll = () => {
		const scrollY = window.scrollY;
		const breakpoint = 150;
		const diff = this.prevScroll - window.scrollY;
		const delta = Math.abs(diff);

		if (delta > 30) {
			if (scrollY > breakpoint) {
				if (diff < 0) {
					this.hidden = true;
					this.active = false;
					this.dom.component.classList.add('menu-hidden');
					this.dom.component.classList.remove('menu-active');
				}

				if (diff > 0) {
					this.hidden = false;
					this.active = true;
					this.dom.component.classList.add('menu-active');
					this.dom.component.classList.remove('menu-hidden');
				}
			}

			if (scrollY < breakpoint) {
				if (diff > 0) {
					this.hidden = false;
					this.active = false;
					this.dom.component.classList.remove('menu-hidden');
					this.dom.component.classList.remove('menu-active');
				}
			}
			this.prevScroll = scrollY;
		}
	};

	bind() {
		this._debounceScroll = debounce(this.handleScroll, 5);
		document.addEventListener('scroll', this._debounceScroll);
	}

	unbind() {}
}
