/**
 * Create a SVG Sprite and cache it in local storage
 */
export default class LocalSvgSprite {
	constructor() {
		var file = MEDIAS + 'images/sprite.svg',
			revision = SP_SPRITE_SVG_REVISION;

		if (
			!document.createElementNS ||
			!document.createElementNS('http://www.w3.org/2000/svg', 'svg')
				.createSVGRect
		)
			return true;

		var isLocalStorage =
				'localStorage' in window && window.localStorage !== null,
			request,
			data,
			insertIT = function () {
				document.body.insertAdjacentHTML('afterbegin', data);
			},
			insert = function () {
				if (document.body) insertIT();
				else document.addEventListener('DOMContentLoaded', insertIT);
			};

		if (isLocalStorage && localStorage.getItem('spritesSVGrev') == revision) {
			data = localStorage.getItem('spritesSVGdata');
			if (data) {
				insert();
				return true;
			}
		}

		try {
			request = new XMLHttpRequest();
			request.open('GET', file, true);
			request.onload = function () {
				if (request.status >= 200 && request.status < 400) {
					data = request.responseText;
					insert();
					if (isLocalStorage) {
						localStorage.setItem('spritesSVGdata', data);
						localStorage.setItem('spritesSVGrev', revision);
					}
				}
			};
			request.send();
		} catch (e) {}
	}
}
