import Component from '~/components/component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class BlockIntro extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.shape = this.dom.component.querySelector('.blue-shape');
	}

	bind() {
		gsap.to(this.shape, {
			yPercent: -40,
			ease: 'none',
			scrollTrigger: {
				trigger: this.dom.component,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 1,
			},
		});
	}
}
