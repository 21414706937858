import Page from '~/pages/page';

export default class DocumentationIndex extends Page {
	constructor(...args) {
		super(...args);

		this._retrieveDOM();
		this._initTls();
	}

	// init() {
	//     super.init();
	// }

	/**
	 * init() {}
	 * bind() {}
	 * unbind() {}
	 * resize(width, height) {}
	 * _destroy() {}
	 */
}
