import Swiper, { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper';
import Component from '~/components/component';

export default class SliderFull extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
		this.initSwiper();
	}

	retrieveDOM() {
		this.dom.prevButton =
			this.dom.component.parentNode.querySelector('.button.prev');
		this.dom.nextButton =
			this.dom.component.parentNode.querySelector('.button.next');
		this.dom.pagination =
			this.dom.component.querySelector('.swiper-pagination');
	}

	initSwiper() {
		const swiper = new Swiper(this.dom.component, {
			modules: [Pagination, Navigation, Mousewheel, Autoplay],
			direction: 'horizontal',
			autoplay: {
				delay: 5000,
			},
			loop: true,
			mousewheel: {
				forceToAxis: true,
			},
			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},
			pagination: {
				el: this.dom.pagination,
				type: 'bullets',
				clickable: true,
			},
		});
	}
}
