import Swiper, { Navigation, Pagination, Mousewheel } from 'swiper';
import Component from '~/components/component';

export default class SliderGeneric extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
		this.initSwiper();
	}

	retrieveDOM() {
		this.dom.prevButton =
			this.dom.component.parentNode.querySelector('.button.prev');
		this.dom.nextButton =
			this.dom.component.parentNode.querySelector('.button.next');
		this.dom.pagination =
			this.dom.component.querySelector('.swiper-pagination');
		this.dom.mobileAuto = this.dom.component.querySelector(
			'[data-slider-mobile-auto="1"]',
		);
	}

	initSwiper() {
		const breakpoints = {
			0: {
				slidesPerView: this.dom.mobileAuto ? 1 : 'auto',
				spaceBetween: this.dom.mobileAuto ? 20 : 0,
			},
			768: {
				slidesPerView: 'auto',
				spaceBetween: 0,
			},
		};

		const swiper = new Swiper(this.dom.component, {
			modules: [Pagination, Navigation, Mousewheel],
			direction: 'horizontal',
			mousewheel: {
				forceToAxis: true,
			},
			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},
			pagination: {
				el: this.dom.pagination,
				type: 'bullets',
				clickable: true,
			},
			breakpoints: breakpoints,
		});
	}
}
