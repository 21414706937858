import Component from '~/components/component';
import debounce from 'lodash.debounce';
export default class MenuMobile extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDom();
		this.init();
	}

	init() {
		this.open = false;
		this.white = false;
	}

	retrieveDom() {
		this.dom.button = this.dom.component.querySelector('.burger');
		this.dom.content = this.dom.component.querySelector('.content');
	}

	toggleMenu = () => {
		if (!this.open) {
			this.dom.button.classList.add('burger-open');
			this.dom.content.classList.add('content-visible');
			if (this.white) this.dom.component.classList.remove('menu-white');
		}
		if (this.open) {
			this.dom.button.classList.remove('burger-open');
			this.dom.content.classList.remove('content-visible');
			if (!this.white) this.dom.component.classList.remove('menu-white');
			if (this.white) this.dom.component.classList.add('menu-white');
		}
		this.open = !this.open;
	};

	handleScroll = () => {
		const scrollY = window.scrollY;
		const halfHeight = window.innerHeight / 2;

		if (scrollY > halfHeight) {
			this.white = true;
			this.dom.component.classList.add('menu-white');
		}

		if (scrollY < halfHeight) {
			this.white = false;
			this.dom.component.classList.remove('menu-white');
		}
	};

	bind() {
		this.dom.button.addEventListener('click', this.toggleMenu);
		this._debounceScroll = debounce(this.handleScroll, 5);
		document.addEventListener('scroll', this._debounceScroll);
	}

	/*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
