import Page from '~/pages/page';
import 'leaflet';
import 'leaflet.markercluster';
import { marked } from 'marked';

const L = window['L'];

const PIN = `<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.4" cx="22.5" cy="22.5" r="22.5" fill="white"/>
<circle cx="22.5" cy="22.5" r="16.5" fill="white"/>
</svg>
`;

export default class ContactIndex extends Page {
	_actionRegisterForm = 'spirit_send_email_register_ajax';

	constructor(...args) {
		super(...args);

		this._retrieveDOM();
		this._initTls();
	}

	init() {
		super.init();
		this.initMap();

		this.fields = [
			this.dom.name,
			this.dom.firstname,
			this.dom.tel,
			this.dom.email,
			this.dom.objet,
			this.dom.message,
			this.dom.accept,
		];
	}

	fadeOut(el) {
		el.classList.add('fade-hidden');
		setTimeout(() => {
			el.classList.remove('fade-hidden');
		}, 300);
	}

	onPinClick = (data, id) => {
		this.fadeOut(this.dom.mapTitle);
		this.fadeOut(this.dom.mapAddress);
		this.fadeOut(this.dom.mapContact);

		setTimeout(() => {
			this.dom.mapTitle.innerHTML = marked.parseInline(data.titre);
			this.dom.mapAddress.innerHTML = marked.parseInline(data.adresse);
			this.dom.mapContact.innerHTML = marked.parseInline(
				data.tel + '<br/>' + data.email,
			);
		}, 290);

		this.pins = document.querySelectorAll('.map-icon');
		this.pins.forEach((pin) => pin.classList.remove('active-pin'));
		this.pins[id].classList.add('active-pin');

		this.map.flyTo([data.lat, data.long], 9);
	};

	initMap = () => {
		this.map = L.map('map', {
			center: [47.068684, 2.436959],
			zoom: 6,
			minZoom: 1,
			maxZoom: 10,
			zoomControl: true,

			maxBounds: [
				[90, 180],
				[-90, -180],
			],
			//maxBoundsViscosity: 1,
		});

		L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
			maxZoom: 18,
			attribution:
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		}).addTo(this.map);

		const markersCluster = new L.MarkerClusterGroup();

		MAP_DATA.forEach((marker, i) => {
			const icon = L.divIcon({
				className: 'map-icon',
				html: PIN,
			});
			L.marker([marker.lat, marker.long], { icon: icon })
				.addTo(this.map)
				.on('click', () => this.onPinClick(marker, i));
		});

		this.map.addLayer(markersCluster);

		this.pins = document.querySelectorAll('.map-icon');
		this.pins[0].classList.add('active-pin');
	};

	_retrieveDOM() {
		// MAP DOM
		this.dom.mapTitle = this.dom.component.querySelector('#map-title');
		this.dom.mapAddress = this.dom.component.querySelector('#map-address');
		this.dom.mapContact = this.dom.component.querySelector('#map-contact');

		// FORM DOM
		this.dom.formContainer = this.dom.component.querySelector('#formContainer');
		this.dom.successContainer =
			this.dom.component.querySelector('#successContainer');
		this.dom.errorMessage = this.dom.component.querySelector('#errorGlobal');
		this.dom.errorMessageSend = this.dom.component.querySelector('#errorSend');
		this.dom.loader = this.dom.component.querySelector('#contact-loader');
		this.dom.name = this.dom.component.querySelector('#name');
		this.dom.firstname = this.dom.component.querySelector('#firstname');
		this.dom.tel = this.dom.component.querySelector('#tel');
		this.dom.email = this.dom.component.querySelector('#email');
		this.dom.objet = this.dom.component.querySelector('#objet');
		this.dom.message = this.dom.component.querySelector('#message');
		this.dom.accept = this.dom.component.querySelector('#accept');

		this.dom.submit = this.dom.component.querySelector('#submit');
	}

	/**
	 * Request "Form Contact Send"
	 * @param data (FormData)
	 */
	_request(data) {
		const xhr = new XMLHttpRequest();

		xhr.open(
			'POST',
			'/wp/wp-admin/admin-ajax.php?action=' + this._actionRegisterForm,
			true,
		);

		xhr.onreadystatechange = function () {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				this._response(JSON.parse(xhr.response));
			}
		}.bind(this);
		xhr.send(data);
	}

	/**
	 * Response to the request to send the form
	 * @param response Ajax
	 */
	_response(response) {
		this.dom.loader.classList.remove('visible-loader');

		if (response.success) {
			this.dom.formContainer.classList.add('hidden');
			this.dom.successContainer.classList.remove('hidden');
			this.dom.errorMessageSend.classList.remove('visible');
			// Bloc "Message bien envoyé"
		} else {
			// Bloc "Erreur dans l'envoi du message"
			this.dom.errorMessageSend.classList.add('visible');
		}
	}

	validateEmail = (email) => {
		const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (regex.test(email)) return true;
		return false;
	};

	showError = (fields) => {
		fields.forEach((field) => field.parentNode.classList.add('input-error'));
		this.dom.errorMessage.classList.add('visible');
	};

	hideError = (fields) => {
		fields.forEach((field) => field.parentNode.classList.remove('input-error'));
		this.dom.errorMessage.classList.remove('visible');
	};

	handleForm = (e) => {
		e.preventDefault();

		const fieldsError = [];
		const fieldsOk = [];

		this.fields.forEach((field) => {
			if (!field.value) {
				fieldsError.push(field);
				return;
			}

			if (field.name == 'email') {
				if (!this.validateEmail(field.value)) {
					fieldsError.push(field);
					return;
				}
			}

			if (field.name == 'tel') {
				if (field.value.length < 5) {
					fieldsError.push(field);
					return;
				}
			}

			if (field.name == 'accept') {
				if (!field.checked == 1) {
					fieldsError.push(field);
					return;
				}
			}

			fieldsOk.push(field);
		});

		if (fieldsOk.length > 0) this.hideError(fieldsOk);
		if (fieldsError.length > 0) this.showError(fieldsError);

		if (fieldsError.length <= 0) {
			const data = new FormData();

			for (let i in fieldsOk) {
				data.append(fieldsOk[i].id, fieldsOk[i].value);
			}

			// Request
			this.dom.loader.classList.add('visible-loader');
			this._request(data);
		}
	};

	bind() {
		this.dom.submit.addEventListener('click', this.handleForm);
	}

	/**
	 * init() {}
	 * bind() {}
	 * unbind() {}
	 * resize(width, height) {}
	 * _destroy() {}
	 */
}
