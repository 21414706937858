import Component from '~/components/component';
import SimpleSharePopin from '../../helpers/simple-share-popin';

export default class Share extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDOM();
	}

	_retrieveDOM() {
		this.dom.buttons = this.dom.component.querySelectorAll('[data-social]');
	}

	init() {
		new SimpleSharePopin(this.dom.buttons);
	}
}
