import { CONFIG } from '../config-project';
import States from '../helpers/states';
import Store from '../helpers/Store';
// import objectFitPolyfill from 'objectFitPolyfill';
// import RafManager from '../helpers/raf-manager';

import { gsap } from "gsap";
// import Scrollbar from 'smooth-scrollbar';
 
// import SmoothScrollMagic from '../helpers/smooth-scroll-magic';

// import Scroll from '../helpers/scroll';
// import WebGLCanvas from '../webgl/webgl-canvas';
// import testFS from '../webgl/shaders/test.frag';

import Component from '../components/component';

export const PageEvent = {
    NEXT: '__pe_next',
    PAGE_DESTROYED: '__pe_page_destroyed'
};

export default class Page extends Component {

    _scrollValue = {
        current: null,
        prev: null
    };

    constructor(page, components) {
        super();

        this.dom = {
			component: page,
			page,
			main: this._find('main'),
			scrollable: this._find('.scrollable'),
			container: this._find('.container'),
			scrollSections: this._findAll('.js-scroll-section')
		};

        this.Components = components;

        if (Store.Scrollbar) Store.Scrollbar.locked = false;

        // if (CONFIG.IS_SMOOTH_SCROLL) {
		// 	this.dom.main.classList.add('is-smooth-scroll');
		// 	this.Scrollbar = Scrollbar.init(this.dom.main);

		// 	// this.SmoothScrollMagic = new SmoothScrollMagic(this.Scrollbar, this.dom.scrollSections);
		// }

        // this.scroll = new Scroll({
        //     direction: 'vertical',
        //     native: false,
        //     noscrollbar: false,
        //     listener: this.dom.page,
        //     section: this.dom.scrollable,
        //     sections: this.dom.scrollSections,
        //     ease: 0.1,
        //     vs: {
        //         preventTouch: true,
        //         passive: States.passive,
        //         sensibility: 1
        //     }
        // });

        // this.webGLCanvas = new WebGLCanvas({
        //     fragmentShader: testFS,
        //     uniforms: {
        //         uTexture: { type: 'sampler2D', value: this._find('img') },
        //         uTime: { type: '1f', value: 0.0 },
        //         uSpeed: { type: '1f', value: 1.0 }
        //     }
        // });
        // this.webGLCanvas.textures['uTexture'].clamp();

        bindAll(this, [
            '_update',
            '_scrollHandler',
            '_next',
            '_destroy'
        ]);

        this._showTl = gsap.timeline({ paused: true });
		this._hideTl = gsap.timeline({
			paused: true,
			onStart: () => {
				this.dom.page.style.position = 'absolute';
				this.dom.page.style.top = -getWindowPosition() + 'px';

				// Reset scroll
				document.scrollingElement.scrollTop = document.scrollingElement.scrollLeft =
				document.body.scrollTop = document.body.scrollLeft =
				document.documentElement.scrollTop = document.documentElement.scrollLeft =
				window.scrollTop = window.scrollLeft = 0;
			},
			onComplete: this._destroy
        });
    }

    _retrieveDOM() {}

    _initTls() {
        this._showTl
            .from(this.dom.page, { autoAlpha: 0, duration: 0 }, 0)

        this._hideTl
            .to(this.dom.page, { autoAlpha: 0, duration: 0 }, 0)
            .add(() => {
                this._next();
            }, 0)
    }

    init() {
        
    }

    bind() {
        [ '_scroll' ]
            .forEach((fn) => this[fn] = this[fn].bind(this));

        window.addEventListener( 'scroll' , this._scroll, { passive: true });
    }

    unbind() {
        window.removeEventListener( 'scroll' , this._scroll );
    }

    mediaLoadedHandler() {
        console.log('PAGE.JS : mediaLoadedHandler');
        this.mediaLoaded = true;
    }

    show() {
        this._showTl.play();
    }

    hide() {
        this._showTl.kill();
        this._hideTl.play();
    }

    _update(delta, time) {
        // if (this.webGLCanvas) {
        //     this.webGLCanvas.setUniform('uTime', time);
        //     this.webGLCanvas.render();
        // }
    }

    _scrollHandler(currentScroll) {
    }

    _scroll() {
        this._scrollValue.current = window.scrollY;
        
        // Example
        // if( this.Components.hasOwnProperty( 'Menu' ) ) {
        //     this.Components[ 'Menu' ]._scroll( this._scrollValue.current );
        // }
    }

    resize(width = States.width, height = States.height) {
        // if (this.scroll)
        //     this.scroll.resize(width, height);

        // if (this.webGLCanvas)
        //     this.webGLCanvas.resize(width, height);
    }

    _next() {
        this.emit(PageEvent.NEXT);
    }

    _destroy() {
        // if (this.scroll)
        //     this.scroll.destroy();

        // if (this.webGLCanvas)
        //     this.webGLCanvas.destroy();

        this._next();
        this.emit(PageEvent.PAGE_DESTROYED);
    }

    _getPersitance() {
        return false;
    }
}
