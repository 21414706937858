import Component from '~/components/component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class SliderOffer extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
	}

	retrieveDOM() {
		this.shape = this.dom.component.querySelector('.shapeBlueWrapper');
	}

	bind() {
		gsap.to(this.shape, {
			yPercent: -20,
			ease: 'none',
			scrollTrigger: {
				trigger: this.dom.component,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 1,
			},
		});
	}
}
