import Component from '~/components/component';
import debounce from 'lodash.debounce';
export default class NavigationAnchors extends Component {
	constructor(...args) {
		super(...args);

		this.prevScroll = 0;
		this.hidden = false;

		this.options = {
			root: null,
			rootMargin: '0px',
			threshold: 1.0,
		};

		this.observer = new IntersectionObserver(
			this.observerCallback,
			this.options,
		);

		this.retrieveDom();
		this.bind();
	}

	goToAnchor = (id) => {
		const el = document.querySelector(id);
		const anchorEl = document.querySelector(`#anchor-${id.substr(1)}`);

		if (el) {
			el.scrollIntoView();
			if (anchorEl) {
				this.anchors.forEach((el) => {
					el.classList.remove('active-anchor');
				});
				anchorEl.classList.add('active-anchor');
			}
		}
	};

	toggleAnchor = (id) => {
		this.anchors.forEach((el) => {
			el.classList.remove('active-anchor');
		});
		this.anchors[id].classList.add('active-anchor');
	};

	retrieveDom() {
		this.anchors = document.querySelectorAll('.anchor');
		this.blocks = document.querySelectorAll('.ct--BlockModulable');
	}

	observerCallback = (entries) => {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0.5) {
				this.anchors.forEach((el) => {
					el.classList.remove('active-anchor');
				});
				const el = document.querySelector('#' + entry.target.id);
				const anchorEl = document.querySelector(`#anchor-${entry.target.id}`);

				if (el) {
					if (anchorEl) anchorEl.classList.add('active-anchor');
				}
			}
		});
	};

	handleScroll = () => {
		const scrollY = window.scrollY;
		const breakpoint = document.body.offsetHeight - 600;
		const diff = this.prevScroll - window.scrollY;
		const delta = Math.abs(diff);

		if (delta > 100) {
			if (window.innerHeight + scrollY > breakpoint) {
				if (diff < 0) {
					this.hidden = true;
					this.dom.component.classList.add('navigation-hidden');
				}

				if (diff > 0) {
					this.hidden = false;
					this.dom.component.classList.remove('navigation-hidden');
				}
			}

			if (window.innerHeight + scrollY < breakpoint) {
				if (diff > 0) {
					this.hidden = false;
					this.dom.component.classList.remove('navigation-hidden');
				}
			}
			this.prevScroll = scrollY;
		}
	};

	bind() {
		this.anchors.forEach((el, i) => {
			el.addEventListener('click', () => this.toggleAnchor(i));
		});

		this.blocks.forEach((block, i) => {
			this.observer.observe(block, i);
		});

		this._debounceScroll = debounce(this.handleScroll, 5);
		document.addEventListener('scroll', this._debounceScroll);

		// SCROLL TO HASH ON LOAD
		setTimeout(() => {
			this.goToAnchor(location.hash);
		}, 1500);

		window.addEventListener('load', () => this.goToAnchor(location.hash));
	}
}
